import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next/';

import Image from 'next/image';

import styles from './404.module.scss';

import Layout from '@components/layout/Layout/Layout';
import TextLink from '@components/ui/TextLink/TextLink';

const Custom404 = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <div className={cn(styles.Container, 'container')}>
        <h3 className={styles.Title}>
          {`${t('GENERIC.CANNOT_FIND_PAGE')} `}
          <TextLink href="/">{t('GENERIC.GO_BACK_TO_HOME')}</TextLink>
        </h3>
        <div className={styles.ImageWrapper}>
          <Image src={require('@assets/images/404-pagina.jpg')} alt="" quality={60} layout="fill" />
        </div>
      </div>
    </Layout>
  );
};

export default Custom404;
